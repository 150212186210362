import { Trans } from '@lingui/macro'
import { InterfaceModalName } from '@uniswap/analytics-events'
import { Currency } from '@uniswap/sdk-core'
import { Trace } from 'analytics'
import Badge from 'components/Badge'
import Modal, { MODAL_TRANSITION_DURATION } from 'components/Modal'
import { USDT as USDT_MAINNET } from 'constants/tokens'
import { Allowance, PermitState } from 'hooks/usePermit2Transfer'
import usePrevious from 'hooks/usePrevious'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import invariant from 'tiny-invariant'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

import { ConfirmationModalContent } from '../TransactionConfirmationModal'
import { ConfirmModalState, PendingConfirmModalState, PendingModalContent } from './PendingModalContent'
import { ErrorModalContent, PendingModalError } from './PendingModalContent/ErrorModalContent'

const StyledL2Badge = styled(Badge)`
  padding: 6px 8px;
`

const StyledL2Logo = styled.img`
  height: 16px;
  width: 16px;
`

function isInApprovalPhase(confirmModalState: ConfirmModalState) {
  return (
    confirmModalState === ConfirmModalState.RESETTING_USDT ||
    confirmModalState === ConfirmModalState.APPROVING_TOKEN ||
    confirmModalState === ConfirmModalState.PERMITTING
  )
}

export function UseConfirmModalState({
  onSwap,
  onRejected,
  allowance,
}: {
  onSwap: () => void
  onRejected: () => void
  allowance: Allowance
}) {
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>(ConfirmModalState.REVIEWING)
  const [approvalError, setApprovalError] = useState<PendingModalError>()
  const [pendingModalSteps, setPendingModalSteps] = useState<PendingConfirmModalState[]>([])

  // This is a function instead of a memoized value because we do _not_ want it to update as the allowance changes.
  // For example, if the user needs to complete 3 steps initially, we should always show 3 step indicators
  // at the bottom of the modal, even after they complete steps 1 and 2.
  const generateRequiredSteps = useCallback(() => {
    const steps: PendingConfirmModalState[] = []
    // Any existing USDT allowance needs to be reset before we can approve the new amount (mainnet only).
    // See the `approve` function here: https://etherscan.io/address/0xdAC17F958D2ee523a2206206994597C13D831ec7#code
    if (
      allowance.state === PermitState.REQUIRED &&
      allowance.needsSetupApproval &&
      allowance.token.equals(USDT_MAINNET) &&
      allowance.allowedAmount.greaterThan(0)
    ) {
      steps.push(ConfirmModalState.RESETTING_USDT)
    }
    if (allowance.state === PermitState.REQUIRED && allowance.needsSetupApproval) {
      steps.push(ConfirmModalState.APPROVING_TOKEN)
    }
    if (allowance.state === PermitState.REQUIRED && allowance.needsPermitSignature) {
      steps.push(ConfirmModalState.PERMITTING)
    }
    steps.push(ConfirmModalState.PENDING_CONFIRMATION)
    return steps
  }, [allowance])

  const catchUserReject = async (e: any, errorType: PendingModalError) => {
    setApprovalError(errorType)
    setConfirmModalState(ConfirmModalState.REJECTED)
    if (didUserReject(e)) return onRejected()
  }

  const performStep = useCallback(
    async (step: ConfirmModalState) => {
      switch (step) {
        case ConfirmModalState.RESETTING_USDT:
          setConfirmModalState(ConfirmModalState.RESETTING_USDT)
          invariant(allowance.state === PermitState.REQUIRED, 'Allowance should be required')
          allowance.revoke().catch((e) => catchUserReject(e, PendingModalError.TOKEN_APPROVAL_ERROR))
          break
        case ConfirmModalState.APPROVING_TOKEN:
          setConfirmModalState(ConfirmModalState.APPROVING_TOKEN)
          invariant(allowance.state === PermitState.REQUIRED, 'Allowance should be required')
          allowance.approve().catch((e) => catchUserReject(e, PendingModalError.TOKEN_APPROVAL_ERROR))
          break
        case ConfirmModalState.PERMITTING:
          setConfirmModalState(ConfirmModalState.PERMITTING)
          invariant(allowance.state === PermitState.REQUIRED, 'Allowance should be required')
          allowance.permit().catch((e) => catchUserReject(e, PendingModalError.TOKEN_APPROVAL_ERROR))
          break
        case ConfirmModalState.PENDING_CONFIRMATION:
          setConfirmModalState(ConfirmModalState.PENDING_CONFIRMATION)
          try {
            onSwap()
          } catch (e) {
            catchUserReject(e, PendingModalError.CONFIRMATION_ERROR)
          }
          break
        default:
          setConfirmModalState(ConfirmModalState.REVIEWING)
          break
      }
    },
    [allowance, onSwap]
  )

  const startSwapFlow = useCallback(() => {
    const steps = generateRequiredSteps()
    setPendingModalSteps(steps)
    performStep(steps[0])
  }, [generateRequiredSteps, performStep])

  const previousSetupApprovalNeeded = usePrevious(
    allowance.state === PermitState.REQUIRED ? allowance.needsSetupApproval : undefined
  )

  useEffect(() => {
    if (
      allowance.state === PermitState.REQUIRED &&
      allowance.needsPermitSignature &&
      // If the token approval switched from missing to fulfilled, trigger the next step (permit2 signature).
      !allowance.needsSetupApproval &&
      previousSetupApprovalNeeded
    ) {
      performStep(ConfirmModalState.PERMITTING)
    }
  }, [allowance, performStep, previousSetupApprovalNeeded])

  const previousRevocationPending = usePrevious(
    allowance.state === PermitState.REQUIRED && allowance.isRevocationPending
  )
  useEffect(() => {
    if (allowance.state === PermitState.REQUIRED && previousRevocationPending && !allowance.isRevocationPending) {
      performStep(ConfirmModalState.APPROVING_TOKEN)
    }
  }, [allowance, performStep, previousRevocationPending])

  useEffect(() => {
    // Automatically triggers the next phase if the local modal state still thinks we're in the approval phase,
    // but the allowance has been set. This will automaticaly trigger the swap.
    if (isInApprovalPhase(confirmModalState) && allowance.state === PermitState.ALLOWED) {
      // Caveat: prevents swap if trade has updated mid approval flow.
      performStep(ConfirmModalState.PENDING_CONFIRMATION)
    }
  }, [allowance, confirmModalState, performStep])

  const onCancel = () => {
    setConfirmModalState(ConfirmModalState.REJECTED)
    setApprovalError(undefined)
  }

  return { startSwapFlow, onCancel, confirmModalState, approvalError, pendingModalSteps }
}

export default function ConfirmSwapModal({
  currency,
  allowance,
  onDismiss,
  onCancel,
  confirmModalState,
  pendingModalSteps,
  approvalError,
}: {
  currency: Currency
  allowance: Allowance
  onDismiss: () => void
  onCancel: () => void
  confirmModalState: ConfirmModalState
  pendingModalSteps: PendingConfirmModalState[]
  approvalError?: PendingModalError
}) {
  const onModalDismiss = useCallback(() => {
    onDismiss()
    setTimeout(() => {
      // Reset local state after the modal dismiss animation finishes, to avoid UI flicker as it dismisses
      onCancel()
    }, MODAL_TRANSITION_DURATION)
  }, [onCancel, onDismiss])

  const modalHeader = useCallback(() => {
    return null
  }, [])

  const modalBottom = useCallback(() => {
    if (confirmModalState === ConfirmModalState.REVIEWING) {
      return null
    }

    if (confirmModalState === ConfirmModalState.REJECTED) {
      return null
    }

    return (
      <PendingModalContent
        hideStepIndicators={pendingModalSteps.length === 1}
        steps={pendingModalSteps}
        currentStep={confirmModalState}
        currency={currency}
        tokenApprovalPending={allowance.state === PermitState.REQUIRED && allowance.isApprovalPending}
        revocationPending={allowance.state === PermitState.REQUIRED && allowance.isRevocationPending}
      />
    )
  }, [confirmModalState, pendingModalSteps, currency, allowance])

  const l2Badge = () => {
    return undefined
  }

  return (
    <Trace modal={InterfaceModalName.CONFIRM_SWAP}>
      <Modal isOpen $scrollOverlay onDismiss={onModalDismiss} maxHeight={90}>
        {approvalError ? (
          <ErrorModalContent
            errorType={approvalError ?? PendingModalError.CONFIRMATION_ERROR}
            onRetry={onModalDismiss}
          />
        ) : (
          <ConfirmationModalContent
            title={<Trans>Transfer Asset</Trans>}
            onDismiss={onModalDismiss}
            topContent={modalHeader}
            bottomContent={modalBottom}
            headerContent={l2Badge}
          />
        )}
      </Modal>
    </Trace>
  )
}
