import { Interface } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { parseUnits } from '@ethersproject/units'
import { Currency } from '@uniswap/sdk-core'
import { toHex } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import BRIDGE_ABI from 'abis/canxium-bridge.json'
import ConfirmTransfer from 'components/Bridge/ConfirmTransfer'
import ConnectWalletForm from 'components/Bridge/ConnectWalletForm'
import TransferDetailForm from 'components/Bridge/TransferDetailForm'
import TransferProgress from 'components/Bridge/TransferProgress'
import { getChainInfo } from 'constants/chainInfo'
import { BRIDGE_CHAIN_IDS, CHAIN_IDS_TO_NAMES, ChainNameToId, SupportedInterfaceChain } from 'constants/chains'
import { BRIDGE_TOKENS } from 'constants/tokens'
import { PermitTransferSignature } from 'hooks/usePermitTransfer'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { PageWrapper } from 'pages/styled'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType, TransferTransactionInfo } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

const iface = new Interface(BRIDGE_ABI)

export default function TransactionPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const addTransaction = useTransactionAdder()
  const deadline = useTransactionDeadline()
  const { account, provider } = useWeb3React()
  const [txHash, setTxHash] = useState<string>()
  const [process, setProcess] = useState<string>('connect')
  const fromChainName = searchParams.get('fromChain') || CHAIN_IDS_TO_NAMES[BRIDGE_CHAIN_IDS[0]]
  const toChainName = searchParams.get('toChain') || CHAIN_IDS_TO_NAMES[BRIDGE_CHAIN_IDS[1]]
  const fromChain: SupportedInterfaceChain = ChainNameToId(fromChainName)
  const toChain: SupportedInterfaceChain = ChainNameToId(toChainName)
  const [network, setNetwork] = useState<{ network1: SupportedInterfaceChain; network2: SupportedInterfaceChain }>({
    network1: fromChain,
    network2: toChain,
  })

  const [token, setToken] = useState<Currency>(BRIDGE_TOKENS[fromChain][0])
  const [inputAmount, setInputAmount] = useState<number>()
  const selectedNetwork = (selectedNetwork: {
    network1: SupportedInterfaceChain
    network2: SupportedInterfaceChain
  }) => {
    setNetwork(selectedNetwork)
  }
  const selectedToken = (selectedToken: Currency) => {
    setToken(selectedToken)
  }

  const selectedTokenAmount = (input: number) => {
    setInputAmount(input)
  }

  const setProcessState = (process: string) => {
    setProcess(process)
  }

  const onConfirmed = async (allowance: PermitTransferSignature | undefined) => {
    const chainInfo = getChainInfo(network.network1)
    if (!account || !provider || !inputAmount || !chainInfo) return
    const amount = parseUnits(inputAmount.toString(), token.decimals).toString()
    const data = iface.encodeFunctionData('transfer', [
      network.network2,
      token.isToken ? token.address : '0x0000000000000000000000000000000000000000',
      amount,
      account,
      token.isToken ? allowance?.nonce : 0,
      token.isToken ? allowance?.deadline : 0,
      token.isToken ? allowance?.signature : '0x01',
    ])

    const tx = {
      from: account,
      to: chainInfo.bridgeContract,
      data,
      ...(token.isNative ? { value: toHex(amount) } : {}),
    }

    const info: TransferTransactionInfo = {
      type: TransactionType.TRANSFER,
      currency: token.name || '',
      amount: inputAmount.toString(),
    }

    try {
      const gasEstimate: BigNumber = await provider.estimateGas(tx)
      const gasLimit = calculateGasMargin(gasEstimate)
      const response = await provider
        .getSigner()
        .sendTransaction({ ...tx, gasLimit })
        .then((response) => {
          if (tx.data !== response.data) {
            if (!response.data || response.data.length === 0 || response.data === '0x') {
              throw new Error('failed to transfer token')
            }
          }

          return response
        })

      setTxHash(response.hash)
      searchParams.set('tx', response.hash)
      setSearchParams(searchParams)
      addTransaction(response, info, deadline?.toNumber())
      setProcessState('progress')
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <PageWrapper>
      {process === 'connect' && (
        <ConnectWalletForm network={network} setProcessState={setProcessState} selectedNetwork={selectedNetwork} />
      )}
      {process === 'detail' && (
        <TransferDetailForm
          setProcessState={setProcessState}
          network={network}
          selectedToken={selectedToken}
          selectedTokenAmount={selectedTokenAmount}
        />
      )}
      {process === 'confirm' && (
        <ConfirmTransfer
          setProcessState={setProcessState}
          onConfirmed={onConfirmed}
          network={network}
          token={token}
          amount={inputAmount || 0}
        />
      )}
      {process === 'progress' && <TransferProgress network={network} transactionHash={txHash} token={token} />}
    </PageWrapper>
  )
}
