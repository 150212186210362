import { useWeb3React } from '@web3-react/core'
import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed2 } from 'components/Row'
import { getChainInfo } from 'constants/chainInfo'
import { BRIDGE_CHAIN_IDS } from 'constants/chains'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useTheme from 'hooks/useTheme'
import React, { useRef, useState } from 'react'
import { ChevronDown } from 'react-feather'
import styled from 'styled-components'
import { TYPE } from 'theme'

const Container = styled.div`
  position: relative;
  z-index: 40;
`

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background};
  padding: 6px 8px;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const LogaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const LogoWrapper = styled.img`
  width: 30px;
  height: 30px;
`

const FlyOut = styled.div`
  background-color: ${({ theme }) => theme.background};
  position: absolute;
  top: 55px;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
`

const NetworkRow = styled(RowBetween)<{ active?: boolean; disabled?: boolean }>`
  padding: 6px 8px;
  background-color: ${({ theme }) => theme.success};
  border-radius: 8px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
  :hover {
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.7)};
  }
`

const Badge = styled.div<{ bgColor?: string }>`
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.background};
  border-radius: 6px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 600;
`

type SwapNetwork = () => void

export default function ButtonDropdown({
  type,
  network,
  swapNetwork,
}: {
  type?: string
  network?: any
  swapNetwork: SwapNetwork
}) {
  const { chainId } = useWeb3React()
  const theme = useTheme()
  const [showMenu, setShowMenu] = useState(false)
  const chainInfo = getChainInfo(network)

  const node = useRef<HTMLDivElement>(null)
  useOnClickOutside(node, () => setShowMenu(false))

  return (
    <Container ref={node}>
      <Wrapper onClick={() => setShowMenu(!showMenu)}>
        <RowFixed2>
          <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
            <LogoWrapper src={chainInfo.logoUrl} />
            <TYPE.main color={theme.white} ml="10px" mr="2px">
              {chainInfo.label}
            </TYPE.main>
          </div>

          <ChevronDown size="20px" />
        </RowFixed2>
      </Wrapper>
      {showMenu && (
        <FlyOut>
          <AutoColumn gap="16px">
            <TYPE.main color={theme.accent1} fontWeight={600} fontSize="16px">
              Select {type}
            </TYPE.main>
            {BRIDGE_CHAIN_IDS.filter((n) => n !== network).map((n, index) => {
              return (
                <NetworkRow
                  key={index}
                  onClick={() => {
                    swapNetwork()
                    setShowMenu(false)
                  }}
                  active={chainId === n}
                >
                  <RowFixed2>
                    <LogaContainer>
                      <LogoWrapper src={getChainInfo(n)?.logoUrl} />
                      <TYPE.main ml="12px" color={theme.white}>
                        {getChainInfo(n)?.label}
                      </TYPE.main>
                    </LogaContainer>
                  </RowFixed2>
                </NetworkRow>
              )
            })}
          </AutoColumn>
        </FlyOut>
      )}
    </Container>
  )
}
