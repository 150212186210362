import { Currency } from '@uniswap/sdk-core'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RowBetween, RowFixed2 } from 'components/Row'
import { BRIDGE_TOKENS } from 'constants/tokens'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useTheme from 'hooks/useTheme'
import React, { useRef, useState } from 'react'
import { ChevronDown } from 'react-feather'
import styled from 'styled-components'
import { TYPE } from 'theme'

const Container = styled.div`
  position: relative;
  z-index: 40;
`

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.background};
  padding: 6px 8px;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const LogaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const LogoWrapper = styled.img`
  width: 30px;
  height: 30px;
`

const FlyOut = styled.div`
  background-color: ${({ theme }) => theme.background};
  position: absolute;
  top: 55px;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
`

const NetworkRow = styled(RowBetween)<{ active?: boolean; disabled?: boolean }>`
  padding: 6px 8px;
  background-color: ${({ theme, active }) => (active ? theme.background : theme.background)};
  border-radius: 8px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
  :hover {
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.7)};
  }
`

const Badge = styled.div<{ bgColor?: string }>`
  background-color: ${({ theme, bgColor }) => bgColor ?? theme.background};
  border-radius: 6px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 600;
`

type SetSelectedToken = (...args: any[]) => void

export default function ButtonDropdownToken({
  setSelectedToken,
  chainId,
}: {
  setSelectedToken: SetSelectedToken
  chainId: number
}) {
  const [chosenToken, setChosenToken] = useState(BRIDGE_TOKENS[chainId][0])
  const theme = useTheme()
  const [showMenu, setShowMenu] = useState(false)

  const node = useRef<HTMLDivElement>(null)
  useOnClickOutside(node, () => setShowMenu(false))
  const handleChooseToken = (chosenToken: Currency) => {
    setChosenToken(chosenToken)
    setSelectedToken(chosenToken)
  }

  return (
    <Container ref={node}>
      <Wrapper onClick={() => setShowMenu(!showMenu)}>
        <RowFixed2>
          <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
            <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={chosenToken} />
            <TYPE.main color={theme.white} ml="10px" mr="2px">
              {chosenToken.name}
            </TYPE.main>
          </div>

          <ChevronDown size="20px" />
        </RowFixed2>
      </Wrapper>
      {showMenu && (
        <FlyOut>
          <AutoColumn gap="16px">
            <TYPE.main color={theme.accent1} fontWeight={600} fontSize="16px">
              Select token
            </TYPE.main>
            {BRIDGE_TOKENS[chainId].map((n: any, index: any) => {
              return (
                <NetworkRow
                  key={index}
                  onClick={() => {
                    handleChooseToken(n)
                    setShowMenu(false)
                  }}
                  active={chosenToken.name == n.name}
                >
                  <RowFixed2>
                    <LogaContainer>
                      <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={n} />
                      <TYPE.main ml="12px" color={theme.white}>
                        {n.name}
                      </TYPE.main>
                    </LogaContainer>
                  </RowFixed2>
                  {n.blurb && <Badge>{n.blurb}</Badge>}
                </NetworkRow>
              )
            })}
          </AutoColumn>
        </FlyOut>
      )}
    </Container>
  )
}
