import { parseUnits } from '@ethersproject/units'
import { Trans } from '@lingui/macro'
import { ChainId, Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ButtonLight } from 'components/Button'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { getChainInfo } from 'constants/chainInfo'
import { SupportedInterfaceChain } from 'constants/chains'
import { BRIDGE_FEE_API } from 'constants/misc'
import usePermit2Transfer, { PermitState } from 'hooks/usePermit2Transfer'
import { PermitTransferSignature } from 'hooks/usePermitTransfer'
import JSBI from 'jsbi'
import React, { useCallback, useEffect, useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { FaArrowRight } from 'react-icons/fa'
import { SlArrowLeft } from 'react-icons/sl'
import { Text } from 'rebass'
import styled from 'styled-components'
import { formatCurrencyAmount } from 'utils/formatCurrencyAmount'

import ConfirmSwapModal, { UseConfirmModalState } from './ConfirmSwapModal'
import { FormTitle } from './FormTitle'
import { FormWrapper } from './FormWrapper'

const TransferNetwork = styled.div`
  border: 1px solid #7b7979;
  border-radius: 10px 10px 0 0;
  padding: 19px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`

const TransferAmount = styled.div`
  border-bottom: 1px solid #7b7979;
  border-left: 1px solid #7b7979;
  border-right: 1px solid #7b7979;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 1px;
`

const EstimatedTransferTime = styled.div`
  border-bottom: 1px solid #7b7979;
  border-left: 1px solid #7b7979;
  border-right: 1px solid #7b7979;
  border-radius: 0 0 10px 10px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  letter-spacing: 1px;
`

const Network1 = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 640px) {
    margin-bottom: 20px;
  }
`

const Network2 = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 640px) {
    margin-top: 20px;
    flex-direction: row-reverse;
  }
`

const Network2Text = styled.div`
  margin-right: 20px;
  text-align: right;
  @media screen and (max-width: 640px) {
    margin-left: 20px;
    text-align: left;
  }
`

const TransferArrow = styled.div`
  text-align: center;
  @media screen and (max-width: 640px) {
    padding-left: 40px;
  }
`

const GreySmallTitle = styled.p`
  margin: 0;
  padding-bottom: 10px;
  letter-spacing: 1px;
  color: #6c7284;
  font-weight: 600;
`

const NetworkTitle = styled.p`
  margin: 0;
  padding-bottom: 10px;
  letter-spacing: 1px;
  color: #3664ed;
  font-weight: 600;
`

type SetProcessState = (...args: any[]) => void

export default function ConfirmTransfer({
  setProcessState,
  network,
  token,
  amount,
  onConfirmed,
}: {
  setProcessState: SetProcessState
  network: { network1: SupportedInterfaceChain; network2: SupportedInterfaceChain }
  token: Currency
  amount: number
  onConfirmed: (allowance: PermitTransferSignature | undefined) => void
}) {
  const [{ showConfirm }, setSwapState] = useState<{
    showConfirm: boolean
  }>({
    showConfirm: false,
  })

  const { account } = useWeb3React()
  const fromNetwork = getChainInfo(network.network1)
  const toNetwork = getChainInfo(network.network2)
  const [fee, setFee] = useState<string | undefined>()

  const backToDetail = () => {
    setProcessState('detail')
  }

  const formatAddress = (address: string | undefined) => {
    if (address === undefined) return '...'
    return address.slice(0, 7) + '...' + address.slice(-4)
  }

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, showConfirm: false }))
  }, [])

  const chainInfo = getChainInfo(network.network1)
  const rawAmount = parseUnits(amount.toString(), token.decimals)
  const transferAmount = CurrencyAmount.fromRawAmount(token, rawAmount.toString())
  const allowance = usePermit2Transfer(transferAmount as CurrencyAmount<Token>, chainInfo.bridgeContract)
  const { startSwapFlow, onCancel, confirmModalState, approvalError, pendingModalSteps } = UseConfirmModalState({
    onSwap: () => {
      onConfirmed(allowance.state === PermitState.ALLOWED ? allowance.permitSignature : undefined)
    },
    onRejected: () => {
      setSwapState((currentState) => ({ ...currentState, showConfirm: false }))
    },
    allowance,
  })

  const startTransfer = () => {
    if (token.isToken && allowance.state !== PermitState.LOADING) {
      setSwapState((currentState) => ({ ...currentState, showConfirm: true }))
      return startSwapFlow()
    }

    onConfirmed(undefined)
  }

  useEffect(() => {
    if (network.network2 == ChainId.CANXIUM || network.network2 == ChainId.CANXIUM_CERIUM) {
      setFee('0')
      return
    }

    fetch(BRIDGE_FEE_API, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        from_chain_id: network.network1,
        chain_id: network.network2,
        token: token.isToken ? token.address : '0x0000000000000000000000000000000000000000',
        receiver: account,
        amount: rawAmount.toString(),
      }), // body data type must match "Content-Type" header
    })
      .then((res) => res.json())
      .then((data) => {
        const amount = CurrencyAmount.fromRawAmount(token, JSBI.BigInt(data.fee))
        const fee = formatCurrencyAmount(amount, 4)
        setFee(fee.toString())
      })
  }, [])

  return (
    <FormWrapper>
      {showConfirm && allowance.state !== PermitState.LOADING && (
        <ConfirmSwapModal
          currency={token}
          allowance={allowance}
          onCancel={onCancel}
          approvalError={approvalError}
          confirmModalState={confirmModalState}
          pendingModalSteps={pendingModalSteps}
          onDismiss={handleConfirmDismiss}
        />
      )}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SlArrowLeft style={{ fontSize: '25px', marginRight: '20px', cursor: 'pointer' }} onClick={backToDetail} />
          <FormTitle>Confirm Transfer</FormTitle>
        </div>
        {/* <AiOutlineClose style={{ fontSize: '25px', cursor: 'pointer' }} /> */}
      </div>
      <TransferNetwork>
        <Network1>
          <img width={50} src={fromNetwork?.logoUrl} alt="logo" />
          <div style={{ marginLeft: '20px' }}>
            <NetworkTitle>{fromNetwork?.label}</NetworkTitle>
            <p style={{ margin: 0 }}>{formatAddress(account)}</p>
          </div>
        </Network1>
        <TransferArrow>
          <FaArrowRight style={{ fontSize: '25px' }} />
        </TransferArrow>
        <Network2>
          <Network2Text>
            <NetworkTitle>{toNetwork?.label}</NetworkTitle>
            <p style={{ margin: 0 }}>{formatAddress(account)}</p>
          </Network2Text>
          <img width={50} src={toNetwork?.logoUrl} alt="logo" />
        </Network2>
      </TransferNetwork>
      <TransferAmount>
        <p>Transfer Amount</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '10px' }}>
            {amount} {token?.name}
          </p>
          <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={token} />
        </div>
      </TransferAmount>
      <TransferAmount>
        <p>Estimated Transfer Fee</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '10px' }}>
            {fee} {token?.name}
          </p>
          <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={token} />
        </div>
      </TransferAmount>
      <TransferAmount>
        <p>Estimated Receive Amount </p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '10px' }}>
            {fee != undefined ? (amount - parseFloat(fee || '0')).toFixed(4) : 0} {token?.name}
          </p>
          <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={token} />
        </div>
      </TransferAmount>
      <EstimatedTransferTime>
        <p>Estimated Transfer Time</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '10px' }}>
            Approx {(((fromNetwork.confirmation || 0) * 2 * (fromNetwork.avgBlockTime || 0)) / 60).toFixed(2)} minutes
          </p>
          <AiOutlineClockCircle style={{ fontSize: '25px' }} />
        </div>
      </EstimatedTransferTime>
      <ButtonLight type="button" $borderRadius="10px" onClick={startTransfer}>
        <Text fontSize={20}>
          <Trans>Transfer</Trans>
        </Text>
      </ButtonLight>
    </FormWrapper>
  )
}
